.mdc-list-item {
  cursor: pointer;
}
.mdc-list-item__start-detail {
  background: rgba(0, 0, 0, .26);
  color: white;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.mdc-list-item__end-detail {
  width: auto;
}

.Lebensmittel {
  background: rgb(85, 178, 35);
}
.Auto, .Transport, .Tanken {
  background: rgb(242, 0, 202);
}
.Hobbies, .Radfahren, .Gaming {
  background: rgb(0, 0, 255);
}
.Kleidung, .Shopping, .Drogerie {
  background: rgb(0, 183, 224);
}
.Übernachtung, .Restaurant, .Eintritte {
  background: rgb(255, 255, 0);
}
.Gesundheit {
  background: rgb(242, 60, 0);
}
.Unterhaltung {
  background: rgb(245, 151, 29);
}
.Yvonne, .Haustiere {
  background: rgb(252, 194, 246)
}
.Fixkosten, .Sonstiges {
  background: rgb(146, 148, 142)
}
